import * as bootstrap from 'bootstrap';
import Cookies from 'js-cookie';

class AuthPrompt {
  private context: AuthPrompt.Context;

  private modal;

  private inputEl;

  constructor(context: AuthPrompt.Context) {
    this.context = context;

    const el = document.getElementById('auth_prompt');
    this.modal = new bootstrap.Modal(el);

    this.inputEl = document.getElementById('auth_prompt_input');
    const btn = document.getElementById('auth_prompt_btn');
    this.inputEl?.addEventListener('focus', () => {
      // @ts-ignore
      window.isInputFocused = true;
    });
    this.inputEl?.addEventListener('blur', () => {
      // @ts-ignore
      window.isInputFocused = false;
    });
    this.inputEl?.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.result();
      }
    });
    btn?.addEventListener('click', (e) => {
      e.preventDefault();
      this.result();
    });
  }

  private result = () => {
    const email = this.inputEl?.value
    if (email) {
      Cookies.set('auth_email', email);
    }

    Cookies.set('auth_url', window.location.href);

    switch (this.context) {
      case AuthPrompt.Context.dashboard:
        break;
      case AuthPrompt.Context.filing:
        const dom = document.getElementById('cik_id');
        const cikType = dom?.dataset['ciktype'];
        const cikId = dom?.dataset['cik'];
        if (cikType && cikId) {
          Cookies.set('auth_cik', `${cikType}___${cikId}`);
        }
        break;
      default:
        break;
    }

    window.location.href = '/signup';
  }

  show = () => {
    this.modal?.show();
    if (window.screen.width > 576) {
      this.inputEl?.focus();
    }
  }

  hide = () => {
    this.modal?.hide();
  }
}

namespace AuthPrompt {
  export enum Context {
    dashboard,
    filing,
    screener,
  }
}

export default AuthPrompt;
